.btn {
    @mixin btn-action {
        &:not(:disabled):not([readonly]) {
            @content;
        }
    }

    --background-color: #{$color-nightly-aurora};
    --background-color-hover: #{$color-nightly-aurora-hover};
    --background-color-active: #{$color-nightly-aurora-active};
    --background-color-disabled: #{$color-nightly-aurora-40};
    --border-color: #{$color-nightly-aurora};
    --border-color-hover: #{$color-nightly-aurora-hover};
    --border-color-active: #{$color-nightly-aurora-active};
    --border-color-disabled: #{$color-nightly-aurora-40};
    --text-color: #{$color-nero};
    --text-color-hover: #{$color-nero};
    --text-color-active: #{$color-nero};
    --text-color-disabled: #{$color-nero};
    --padding: 15px 23px;
    --icon-color: currentColor;
    --icon-color-hover: currentColor;
    --icon-color-active: currentColor;
    --icon-color-disabled: currentColor;
    --icon-size: 20px;

    @extend .font-btn;

    display: inline-flex;
    align-items: center;
    column-gap: $i-s;
    background-color: var(--background-color);
    border: 1px solid;
    border-color: var(--border-color);
    color: var(--text-color);
    padding: var(--padding);
    border-radius: 7px;
    text-decoration: none;
    font-family: inherit;

    @include transition(background-color, border-color, color);

    @include btn-action {
        @include hover {
            --icon-color: var(--icon-color-hover);

            background-color: var(--background-color-hover);
            border-color: var(--border-color-hover);
            color: var(--text-color-hover);
        }

        &:active {
            --icon-color: var(--icon-color-active);

            background-color: var(--background-color-active);
            border-color: var(--border-color-active);
            color: var(--text-color-active);
        }
    }

    @include laptop {
        --padding: #{$i-s $i-l};
    }

    &:disabled {
        @include disabled;

        filter: opacity(0.3);
        // --background-color: var(--background-color-disabled);
        // --text-color: var(--text-color-disabled);
        // --icon-color: var(--icon-color-disabled);
        // --border-color: var(--background-color-disabled);
    }

    &__text {
        width: 100%;
        white-space: nowrap;

        @include transition(color);
    }

    &__icon {
        flex-shrink: 0;
        width: var(--icon-size);
        height: var(--icon-size);
        // min-width: 24px;
        // min-height: 24px;
        // max-width: 24px;
        // max-height: 24px;
        fill: var(--icon-color);

        @include transition(fill);
    }

    &--outlined {
        --background-color: none;
        --background-color-hover: none;
        --background-color-active: none;
        --border-color: #{$color-nightly-aurora};
        --border-color-hover: #{$color-nightly-aurora};
        --border-color-active: #{$color-nightly-aurora-active};
        --text-color: #{$color-white};
        --text-color-hover: #{$color-nightly-aurora};
        --text-color-active: #{$color-nightly-aurora-active};
        --icon-color: #{$color-nightly-aurora};
        --icon-color-hover: #{$color-nightly-aurora-hover};
        --icon-color-active: #{$color-nightly-aurora-active};
    }

    &--stroke {
        --background-color: none;
        --background-color-hover: none;
        --background-color-active: none;
        --border-color: #{$color-nightly-aurora};
        --border-color-hover: #{$color-nightly-aurora};
        --border-color-active: #{$color-nightly-aurora-active};
        --text-color: #{$color-white};
        --text-color-hover: #{$color-nightly-aurora};
        --text-color-active: #{$color-nightly-aurora-active};
        --icon-color: #{$color-nightly-aurora};
        --icon-color-hover: #{$color-nightly-aurora-hover};
        --icon-color-active: #{$color-nightly-aurora-active};
    }

    &--clear {
        --background-color: none;
        --background-color-hover: none;
        --background-color-active: none;
        --border-color: transparent;
        --border-color-hover: transparent;
        --border-color-active: transparent;
        --text-color: #{$color-light-gray-50};
        --text-color-hover: #{$color-light-gray};
        --text-color-active: #{$color-white};
        --icon-color: #{$color-nightly-aurora};
        --icon-color-hover: #{$color-nightly-aurora-hover};
        --icon-color-active: #{$color-nightly-aurora-active};

        padding-left: 0;
        padding-right: 0;

        &:disabled {
            --icon-color: #{$color-light-gray-50};
        }
    }

    &--accent-clear {
        --background-color: none;
        --background-color-hover: none;
        --background-color-active: none;
        --border-color: transparent;
        --border-color-hover: transparent;
        --border-color-active: transparent;
        --text-color: #{$color-nightly-aurora};
        --text-color-hover: #{$color-nightly-aurora-hover};
        --text-color-active: #{$color-nightly-aurora-active};
        --icon-color: #{$color-nightly-aurora};
        --icon-color-hover: #{$color-nightly-aurora-hover};
        --icon-color-active: #{$color-nightly-aurora-active};

        padding-left: 0;
        padding-right: 0;
    }

    &--underline {
        --icon-color: #{$color-light-gray};
        --icon-color-hover: #{$color-white};
        --icon-color-active: #{$color-white};
        --text-color: #{$color-light-gray};
    }

    &--underline-accent {
        --background-color: none;
        --background-color-hover: none;
        --background-color-active: none;
        --border-color: transparent;
        --border-color-hover: transparent;
        --border-color-active: transparent;
        --text-color: #{$color-nightly-aurora};
        --text-color-hover: #{$color-nightly-aurora-hover};
        --text-color-active: #{$color-nightly-aurora-active};
        --icon-color: #{$color-nightly-aurora};
        --icon-color-hover: #{$color-nightly-aurora-hover};
        --icon-color-active: #{$color-nightly-aurora-active};

        padding-left: 0;
        padding-right: 0;

        .btn__text {
            border-bottom: 1px solid currentColor;
        }
    }

    &--underline-important {
        --background-color: none;
        --background-color-hover: none;
        --background-color-active: none;
        --border-color: transparent;
        --border-color-hover: transparent;
        --border-color-active: transparent;
        --text-color: #{$color-alt-accent-2};
        --text-color-hover: #{$color-alt-accent-2-hover};
        --text-color-active: #{$color-alt-accent-2-click};
        --icon-color: #{$color-alt-accent-2};
        --icon-color-hover: #{$color-alt-accent-2-hover};
        --icon-color-active: #{$color-alt-accent-2-click};

        padding-left: 0;
        padding-right: 0;

        .btn__text {
            border-bottom: 1px solid currentColor;
        }
    }

    &--round {
        --background-color: #{$color-nero};
        --background-color-hover: #{$color-nero};
        --background-color-active: #{$color-nero};
        --text-color: #{$color-nightly-aurora};
        --text-color-hover: #{$color-nightly-aurora-hover};
        --text-color-active: #{$color-nightly-aurora-active};
        --icon-color: #{$color-nightly-aurora};
        --icon-color-hover: #{$color-nightly-aurora-hover};
        --icon-color-active: #{$color-nightly-aurora-active};

        border: none;
        border-radius: 40px;
    }

    &--round.btn--icon-only {
        --padding: 4px;
    }

    &--min {
        --padding: 9px 15px;

        @include font(16px, 500, 120%);

        @include laptop {
            padding: 9px 15px;
        }
    }

    &--medium {
        --padding: 10px;

        @include font(16px, 500, 124%);
    }

    &--small {
        --padding: 7px 11px;
        --icon-size: 20px;

        &--icon-only {
            --padding: 5px;
        }

        @include font(14px, 600, 140%);
    }

    &--fullwidth {
        width: 100%;
        text-align: center;
    }
}
