.card {
    background-color: $color-nero-light;
    padding: $i-3xl $i-l;
    border-radius: 16px;

    @include transition(opacity);

    @include tablet {
        padding: $i-l;
    }

    @include mobile {
        padding: $i-3xl $i-s;
    }

    &--shadowed {
        box-shadow: $shadow-2;
    }

    &--float {
        width: 560px;
        padding: 76px $i-5xl $i-5xl $i-5xl;

        @include laptop {
            width: 504px;
        }

        @include tablet {
            width: 396px;
            padding: $i-5xl $i-l $i-3xl $i-l;
        }
    }
}
