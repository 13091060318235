@import "functions";
@import "mixins";
@import "variables";
@import "fonts";
@import "indents";
@import "helpers";

* {
    box-sizing: border-box;
}

body {
    @include font(16px);

    margin: 0;
    font-family: $font-family-main;
    background-color: $color-nero;
    color: $color-white;
}
