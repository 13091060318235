@font-face {
    font-family: "Manrope";
    font-weight: 400;
    font-style: normal;
    font-display: swap;

    src: url("../fonts/Manrope-Regular.woff2") format("woff2"),
        url("../fonts/Manrope-Regular.woff") format("woff"),
        url("../fonts/Manrope-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Manrope";
    font-weight: 500;
    font-style: normal;
    font-display: swap;

    src: url("../fonts/Manrope-Medium.woff2") format("woff2"),
        url("../fonts/Manrope-Medium.woff") format("woff"),
        url("../fonts/Manrope-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Manrope";
    font-weight: 600;
    font-style: normal;
    font-display: swap;

    src: url("../fonts/Manrope-SemiBold.woff2") format("woff2"),
        url("../fonts/Manrope-SemiBold.woff") format("woff"),
        url("../fonts/Manrope-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Manrope";
    font-weight: 700;
    font-style: normal;
    font-display: swap;

    src: url("../fonts/Manrope-Bold.woff2") format("woff2"),
        url("../fonts/Manrope-Bold.woff") format("woff"),
        url("../fonts/Manrope-Bold.ttf") format("truetype");
}

.h1,
.h2,
.h3,
.p1,
.p2,
.p3,
.p4 {
    margin: 0;
}

.h1 {
    @include font(48px, 600, 120%);

    @include laptop {
        @include font(40px, 600, 120%);
    }

    @include tablet {
        @include font(24px, 600, 120%);
    }
}

.h2 {
    @include font(40px, 600, 120%);

    @include laptop {
        @include font(32px, 600, 120%);
    }

    @include tablet {
        @include font(20px, 500, 120%);
    }
}

.h3 {
    @include font(24px, 600, 120%);

    @include laptop {
        @include font(20px, 600, 120%);
    }

    @include tablet {
        @include font(18px, 500, 120%);
    }
}

.p1 {
    @include font(18px, 500, 132%);

    @include tablet {
        @include font(16px, 500, 120%);
    }
}

.p2 {
    @include font(16px, 500, 120%);

    @include tablet {
        @include font(14px, 500, 120%);
    }
}

.p3 {
    @include font(14px, 600, 120%);

    @include tablet {
        @include font(12px, 500, 120%);
    }
}

.p4 {
    @include font(12px, 600, 120%);

    @include tablet {
        @include font(10px, 600, 120%);
    }
}

.font-lead {
    @include font(20px, 700, 145%);

    @include tablet {
        @include font(14px, 700, 145%);
    }
}

.font-btn {
    @include font(20px, 600, 100%);

    @include tablet {
        @include font(16px, 600, 124%);
    }
}
