.user {
    position: relative;
    display: flex;
    align-items: center;

    @include hover;

    &__icon-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background-color: $color-nero-light;
        border-radius: 50%;
    }

    &__avatar {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
        border-radius: 50%;
    }

    &__icon {
        width: 20px;
        height: 20px;
        fill: $color-nightly-aurora;
    }

    &__notifications-icon {
        position: absolute;
        left: -5px;
        top: 0;
        width: 14px;
        height: 14px;
        fill: $color-mistake;
    }

    &__info {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        color: inherit;
        @include transition(color);

        &:hover {
            color: $color-light-gray-50;

            .user__role {
                color: $color-alt-text-20;
            }
        }
    }

    &__role-wrapper {
        display: flex;
        align-items: center;
    }

    &__role-icon {
        width: 1em;
        height: 1em;
        fill: $color-nightly-aurora;
        // TODO
        margin-right: 4px;
    }

    &__role {
        color: rgba($color-light-gray, 0.5);
        margin-top: 4px;
        @include transition(color);
    }

    &__notifications {
        position: absolute;
        right: 0;
        bottom: -$i-m;
        transform: translateY(100%);

        @include tablet {
            left: 0;
        }
    }
}

// TODO move to component
.notifications {
    display: flex;
    flex-direction: column;
    row-gap: $i-m;
    width: 361px;
    max-height: 300px;
    padding: $i-l $i-m;
    background-color: $color-nero-light;
    border-radius: 8px;
    box-shadow: $shadow-2;
    overflow: auto;
    z-index: 1;

    &::-webkit-scrollbar {
        width: 6px;
        border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
        background-color: $color-alt-text-20;
        border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px $color-nightly-aurora;
        border-radius: 8px;
    }

    @include transition(opacity);

    @include laptop {
        width: 317px;
    }

    @include tablet {
        width: 252px;
    }

    &__notification {
        @extend .p3;

        &:not(&:last-child) {
            padding-bottom: $i-m;
            border-bottom: 1px solid $color-light-gray-50;
        }

        & b {
            color: $color-soft-blue;
            font-weight: 600; // как в .p3
        }

        & a {
            text-decoration: none;
            color: currentColor;
            font-weight: 600;
        }
    }
}

// TODO move to component
.reminder {
    // TODO
    padding-top: 36px;

    &__close-icon-wrapper {
        position: absolute;
        right: $i-m;
        top: $i-xs;
    }

    &__close-icon {
        width: 20px;
        height: 20px;
        fill: currentColor;
    }

    &__notification {
        display: flex;
        align-items: center;
    }

    &__icon {
        min-width: 16px;
        min-height: 16px;
        max-width: 16px;
        max-height: 16px;
        fill: $color-soft-blue;
        margin-right: $i-m;
    }
}
