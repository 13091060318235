@import "@styles/layouts/auth";
@import "@styles/components/tooltip";

.content {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: $i-5xl;
    overflow: hidden;

    &__item {
        position: absolute;
        display: flex;
        flex-direction: column;

        @include mobile {
            width: 100%;
        }
    }
}

.enter {
    &__title {
        @extend .h2;

        margin-bottom: $i-3xl;
    }

    &__phone {
        // TODO
        margin-bottom: 40px;
    }

    &__divider {
        margin: $i-3xl 0;
    }
}

.code {
    &__title {
        @extend .h2;

        margin-bottom: $i-l;
    }

    &__subtitle {
        @extend .p1;

        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin: 0 $i-xs $i-3xl 0;
        color: $color-light-gray;

        @include mobile {
            margin-right: 0;
            flex-direction: column;
            align-items: flex-start;
            gap: 14px;
        }

        &--column {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__input {
        // TODO
        margin-bottom: 40px;
    }

    &__timer-wrapper {
        @extend .p1;

        color: $color-nightly-aurora;
    }
}

.fields {
    display: flex;
    flex-direction: column;
}
