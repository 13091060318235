.input {
    @extend .p2;

    --border-color: #{$color-nightly-aurora};
    --border-color--rgb: #{hexToRGB($color-nightly-aurora)};

    background: none;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(var(--border-color--rgb), 0.4);
    border-radius: 8px;
    padding: $i-m $i-s;
    font-family: inherit;
    color: $color-white;
    outline: none;

    @include transition(border-color, color);

    @include tablet {
        padding: $i-m $i-s;
    }

    &:focus {
        border-color: var(--border-color);
    }

    &:disabled {
        background-color: transparent;
        color: $color-gray;

        @include disabled;
    }

    &--danger {
        --border-color: #{$color-mistake};
        --border-color--rgb: #{hexToRGB($color-mistake)};
    }

    &--not-resizable {
        resize: none;
    }

    &--password, &--space-right {
        padding-right: 56px;
    }

    &__wrapper {
        display: inline-flex;
        flex-direction: column;
    }

    &__label {
        @extend .p1;

        display: inline-block;
        margin-bottom: $i-xs;
    }

    &__error {
        @extend .p2;

        display: inline-block;
        margin-top: 8px;
        color: $color-mistake;
    }

    &__more-wrap {
        text-align: right;
    }

    &__more {
        margin-top: 4px;
    }
}

.input-wrap {
    position: relative;
}

.pass-controll {
    --icon-color: #{$color-nightly-aurora-40};

    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    background: none;
    border: none;
    outline: none;
    fill: var(--icon-color);
    cursor: pointer;

    &--open &__icon--close {
        display: block;
    }

    &--open &__icon--open {
        display: none;
    }

    &:hover {
        --icon-color: #{$color-nightly-aurora};
    }

    &__icon {
        width: 20px;
        height: 20px;
        fill: inherit;

        &--close {
            display: none;
        }
    }
}

.input {
    &:focus + .pass-controll {
        fill: $color-nightly-aurora;
    }
}

.input-multiple {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    &__input {
        order: 1;
        width: calc(100% - 110px);

        @include laptop {
            width: calc(100% - 130px);
        }
    }

    &__delete {
        order: 2;
    }

    &__error {
        order: 3;
    }
}

.input-multiple-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.input-multiple {
    &:first-child {
        .input-multiple__input {
            width: 100%;
        }
        .input-multiple__delete {
            display: none;
        }
    }

    &:last-child {
        .input__more-wrap {
            display: block;
        }
    }

    &:nth-child(n+2) {
        .input__label {
            display: none;
        }
    }
}

.input-multiple-wrap:not(:last-child) .input__more-wrap{
    display: none;
}

.input__button {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translate(-50%, -50%);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: block;

    svg {
        fill: $color-nightly-aurora;
        width: 20px;
        height: 20px;
    }
}
