.container {
    width: 100%;
    height: 100%;
    max-width: $container-desktop;
    margin: 0 auto;
    padding: 0 16px;

    @include laptop {
        max-width: $container-laptop;
    }

    @include tablet {
        max-width: $container-tablet;
    }

    @include mobile {
        // max-width: $container-mobile;
        max-width: 100%;
        padding: 0 20px;
    }
}
