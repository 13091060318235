.toasts {
    position: fixed;
    left: 60px;
    bottom: 60px;
    display: flex;
    flex-direction: column;
    row-gap: $i-s;
    z-index: 100;

    @include mobile {
        left: 10px;
        bottom: 10px;
        width: calc(100% - 20px);
    }
}

.toast {
    display: flex;
    align-items: center;
    background-color: $color-nero-light;
    color: $color-nightly-aurora;
    padding: $i-m $i-s;
    border-radius: 8px;
    box-shadow: $shadow-2;

    @include transition(opacity, transform);

    &--warning {
        color: $color-mistake;
    }

    &--prepared {
        opacity: 0;
        transform: translateY(100%);
    }

    &--removed {
        opacity: 0;
        transform: translateX(100%);
    }

    &__icon {
        width: 20px;
        height: 20px;
        fill: currentColor;
        margin-right: $i-m;
    }

    &__text {
        @extend .p2;
    }
}

.toast-card {
    position: relative;
    width: max-content;
    max-width: 326px;
    padding: 36px 16px 20px 52px;
    background-color: $color-nero-light;
    border-radius: 8px;
    box-shadow: $shadow-2;

    &__close {
        position: absolute;
        top: 0;
        right: 0;
    }

    &__icon {
        position: absolute;
        top: 36px;
        left: 16px;
        width: 20px;
        height: 20px;
        fill: $color-nightly-aurora;
    }

    &__title {
        @extend .p2;

        margin: 0 0 8px;
        color: $color-nightly-aurora;
    }

    &__text {
        @extend .p3;
    }

    &__controls {
        margin-top: 8px;
    }

    strong {
        font-weight: inherit;
        color: $color-soft-blue;
    }
}
