.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: $i-3xl 0;

    &__logo {
        margin-right: $i-l;
    }

    &__link {
        @extend .p2;
        @include item-horizontal-gap($i-xl);
    }

    &__right {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__enter {
        @extend .p1;

        display: flex;
        align-items: center;
        text-decoration: none;
        color: $color-white;
    }

    &__enter-icon {
        width: 20px;
        height: 20px;
        fill: $color-nightly-aurora;
        margin-right: $i-s;
    }

    &__right-menu-opener {
        display: none;
        width: 38px;
        height: 23px;
        fill: $color-nightly-aurora;
    }

    &__lang {
        display: none;

        &.loaded {
            display: flex;
        }
    }

    &__user {
        display: flex;
        align-items: center;
        column-gap: 32px;
    }
}

@include tablet {
    .header {
        flex-direction: column;
        padding-bottom: $i-l;
        border-bottom: 1px solid rgba($color-nightly-aurora, 0.4);

        &__logo {
            margin-bottom: $i-l;
        }

        &__links {
            display: none;
        }

        &__right {
            width: 100%;
        }

        &__right-menu-opener {
            display: block;
        }

        &__lang, &__lang.loaded {
            display: none;
        }
    }
}
