.hidden {
    display: none !important;
}

.invisible {
    opacity: 0;
    pointer-events: none;
}

.scroll-lock {
    overflow: hidden;
}

.w-full {
    width: 100% !important;
}

.overflow-hidden {
    overflow: hidden;
}

.text-color {
    &-nightly-aurora {
        color: $color-nightly-aurora;
    }

    &-nero {
        color: $color-nero;
    }

    &-gray-bg {
        color: $color-gray-bg;
    }

    &-gray {
        color: $color-gray;
    }

    &-mistake {
        color: $color-mistake;
    }

    &-soft-blue {
        color: $color-soft-blue;
    }

    &-nero-light {
        color: $color-nero-light;
    }

    &-white {
        color: $color-white;
    }

    &-light-gray {
        color: $color-light-gray;
    }

    &-light-gray-50 {
        color: $color-light-gray-50;
    }

    &-dark-gray {
        color: $color-dark-gray;
    }

    &-accent-2 {
        color: $color-alt-accent-2;
    }
}

.bg-color {
    &-nightly-aurora {
        color: $color-nightly-aurora;
    }

    &-nero {
        color: $color-nero;
    }

    &-gray-bg {
        color: $color-gray-bg;
    }

    &-gray {
        color: $color-gray;
    }

    &-mistake {
        color: $color-mistake;
    }

    &-soft-blue {
        color: $color-soft-blue;
    }

    &-nero-light {
        color: $color-nero-light;
    }

    &-white {
        color: $color-white;
    }

    &-light-gray {
        color: $color-light-gray;
    }

    &-light-gray-50 {
        color: $color-light-gray-50;
    }

    &-dark-gray {
        color: $color-dark-gray;
    }
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}
