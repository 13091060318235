.right-menu {
    --translateX: 100%;

    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    pointer-events: none;
    overflow: hidden;
    z-index: 100;

    @include tablet {
        &[data-opened] {
            --translateX: 0;

            pointer-events: auto;
        }
    }

    &__content {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        width: 374px;
        height: 100%;
        padding: 80px $i-5xl 0;
        background-color: $color-nero;
        border-left: 1px solid rgba($color-nightly-aurora, 0.4);
        overflow: auto;
        transform: translateX(var(--translateX));

        @include transition(transform);
    }

    &__close {
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
        fill: $color-nightly-aurora;

        margin-bottom: $i-4xl;
        align-self: flex-end;
    }

    &__menu {
        width: 220px;
        align-self: center;
    }

    &__divider {
        width: 100%;
        min-height: 1px;
        margin: $i-3xl 0;
        background-color: rgba($color-nightly-aurora, 0.4);
    }

    &__links {
        display: flex;
        flex-direction: column;
        align-self: center;
    }

    &__link {
        @include font(20px, 600);

        color: $color-white;
        text-decoration: none;

        @include item-vertical-gap($i-l);

        @include tablet-min {
            @include font(18px, 600);
        }
    }
}
