@use "sass:list";

$sizes: [0, '0'], [$i-xs, 'xs'], [$i-s, 's'], [$i-m, 'm'], [$i-l, 'l'], [$i-xl, 'xl'], [$i-2xl, '2xl'], [$i-3xl, '3xl'], [$i-4xl, '4xl'], [$i-5xl, '5xl'];
$sides: ['', ''], ['t', '-top'], ['r', '-right'], ['b', '-bottom'], ['l', '-left'];

@each $size in $sizes {
    $size0: list.nth($size, 1);
    $size1: list.nth($size, 2);

    @each $side in $sides {
        $side0: list.nth($side, 1);
        $side1: list.nth($side, 2);

        .m#{$side0}-#{$size1} {
            margin#{$side1}: $size0;
        }

        .p#{$side0}-#{$size1} {
            padding#{$side1}: $size0;
        }
    }

    .column-gap-#{$size1} {
        margin-right: -#{$size0};

        > * {
            margin-right: $size0;
        }
    }

    .row-gap-#{$size1} {
        margin-bottom: -#{$size0};

        > * {
            margin-bottom: $size0;
        }
    }
}
