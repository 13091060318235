.link {
    @mixin action {
        &:not([disabled]) {
            @content;
        }
    }

    --base-color: #{$color-light-gray};
    --base-color-hover: #{$color-gray-bg};
    --base-color-active: #{$color-white};
    --base-color-disabled: rgba(#{hexToRGB($color-light-gray)}, 0.5);
    --color: var(--base-color);

    @extend .p2;

    display: inline-flex;
    align-items: center;
    width: max-content;
    color: var(--color);
    border-bottom-color: var(--color);
    text-decoration: none;

    &[disabled] {
        --color: var(--base-color-disabled);

        @include disabled;
    }

    &--clear {
        --base-color: #{$color-white};
    }

    &:not(&--clear) {
        @include transition(color, border-bottom-color);

        @include action {
            @include hover {
                --color: var(--base-color-hover);
            }

            &:active {
                --color: var(--base-color-active);
            }
        }
    }

    &--underline {
        border-bottom-width: 1px;
        border-bottom-style: solid;
    }

    &--accent {
        --base-color: #{$color-nightly-aurora};
        --base-color-hover: #{$color-nightly-aurora-hover};
        --base-color-active: #{$color-nightly-aurora-active};
        --base-color-disabled: rgba(#{hexToRGB($color-nightly-aurora)}, 0.3);
    }

    &--bigger {
        font-weight: 500;
        font-size: 18px;
        line-height: 120%;
    }

    &__icon {
        width: 1em;
        height: 1em;
        fill: var(--color);

        @include transition(fill);
    }
}
