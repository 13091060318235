.tooltip-wrap {
    position: relative;
    display: inline-block;
}

.tooltip {
    position: absolute;
    bottom: 100%;
    right: 20px;
    width: 323px;
    padding: 36px 16px 20px;
    background: $color-nero-light;
    box-shadow: $shadow-2;
    border-radius: 8px 8px 0 8px;
    display: none;

    @include tablet {
        right: auto;
        left: 0;
    }

    @include mobile {
        width: 250px;
    }

    &--open {
        display: block;
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
    }

    &__text {
        p {
            margin: 0 0 10px;
        }

        p:last-child {
            margin-bottom: 0;
        }

        strong {
            color: $color-soft-blue;
        }

        ol {
            padding: 0;
            margin: 16px 0;
            list-style: none;
            counter-reset: count;

            li {
                position: relative;
                padding-left: 24px;
                margin-bottom: 8px;

                &::before {
                    counter-increment: count;
                    content: counter(count) '.';
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            color: $color-nightly-aurora;
            text-decoration: none;

            &:hover {
                color: $color-nightly-aurora-hover;
            }

            &:active {
                color: $color-nightly-aurora-active;
            }
        }
    }


}
