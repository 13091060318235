// Screen sizes
$desktop: 1440px;
$laptop: 1024px;
$tablet: 768px;
$mobile: 416px;
$tablet-min: 576px;

// Retina
$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;

// Fonts
$font-family-main: 'Manrope', 'Arial', -apple-system, sans-serif;

// Indents
$i-xs: 8px;
$i-s: 12px;
$i-m: 16px;
$i-l: 20px;
$i-xl: 24px;
$i-2xl: 28px;
$i-3xl: 32px;
$i-4xl: 40px;
$i-5xl: 60px;

// Colors
$color-nightly-aurora: #9CF1BE;
$color-nightly-aurora-hover: #6ED296;
$color-nightly-aurora-active: #61B482;
$color-nightly-aurora-40: rgba($color-nightly-aurora, 0.4);
$color-nero: #212121;
$color-nero-50: rgba($color-nero, 0.5);
$color-gray-bg: #F5F4F9;
$color-gray: #A8B0BD;
$color-mistake: #F67A98;
$color-soft-blue: #97AEFF;
$color-nero-light: #2B2B2B;
$color-white: #FFFFFF;
$color-light-gray: #CED2D9;
$color-light-gray-50: rgba($color-light-gray, 0.5);
$color-dark-gray: #8C96A6;
$color-alt-text-20: rgba($color-light-gray, 0.2);
$color-alt-accent-2: #E4B253;
$color-alt-accent-2-hover: #EDA621;
$color-alt-accent-2-click: #D29012;

// Box-shadows
$shadow: 0px 10px 20px rgba(33, 33, 33, 0.06);
$shadow-2: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 10px 20px rgba(0, 0, 0, 0.3);

// Containers
$container-desktop: 1160px;
$container-laptop: 940px;
$container-tablet: 620px;
$container-mobile: 376px;
