@import "@styles/layouts/template";

@import "@styles/components/card";
@import "@styles/components/input";
@import "@styles/components/button";
@import "@styles/components/divider";
@import "@styles/components/footer";

.background-z {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    overflow: hidden;
    pointer-events: none;
}

.background-z__icon {
    position: absolute;
    // TODO adaptive
    width: 940px;
    height: 360px;
    left: 0;
    bottom: $i-5xl;

    @include laptop {
        width: 682px;
    }

    @include mobile {
        width: 346px;
    }
}
