@mixin font($size: null, $weight: null, $lineHeight: null) {
    @if ($size != null) {
        font-size: $size;
    }

    @if ($weight != null) {
        font-weight: $weight;
    }

    @if ($lineHeight != null) {
        line-height: $lineHeight;
    }
}

@mixin item-property-gap($property, $size) {
    #{$property}: $size;

    &:last-child {
        #{$property}: 0;
    }
}

@mixin item-vertical-gap($size) {
    @include item-property-gap(margin-bottom, $size);
}

@mixin item-horizontal-gap($size) {
    @include item-property-gap(margin-right, $size);
}

@mixin property-gap($property, $size) {
    #{$property}: -$size;

    > * {
        #{$property}: $size;
    }
}

@mixin vertical-gap($size) {
    @include property-gap(margin-bottom, $size);
}

@mixin horizontal-gap($size) {
    @include property-gap(margin-right, $size);
}

@mixin gap($size) {
    @include vertical-gap($size);
    @include horizontal-gap($size);
}

@mixin desktop {
    @media screen and (max-width: $desktop) {
        @content;
    }
}

@mixin laptop {
    @media screen and (max-width: $laptop) {
        @content;
    }
}

@mixin tablet {
    @media screen and (max-width: $tablet) {
        @content;
    }
}

@mixin tablet-min {
    @media screen and (max-width: $tablet-min) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: $mobile) {
        @content;
    }
}

@mixin retina {
    @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
        @content;
    }
}

@mixin transition($args...) {
    transition-property: $args;
    transition-duration: 0.15s;
    transition-timing-function: linear;
}

@mixin transition-long($args...) {
    transition-property: $args;
    transition-duration: 0.3s;
    transition-timing-function: linear;
}

@mixin hover($cursor: pointer) {
    @media (min-width: $tablet) {
        &:hover {
            @if ($cursor != null) {
                cursor: $cursor;
            }

            @content;
        }
    }
}

@mixin disabled {
    cursor: not-allowed;

    @content;
}
