.side-menu {
    --text-color-hover: #{$color-white};

    display: flex;
    flex-direction: column;
    align-self: center;

    &--left {
        align-self: unset;
        min-width: 281px;
        max-width: 281px;
        padding-right: $i-l;
        margin: $i-5xl $i-l $i-5xl 0;
        border-right: 1px solid rgba($color-nightly-aurora, 0.2);

        @include laptop {
            min-width: 231px;
            max-width: 231px;
            padding-right: $i-s;
            margin: $i-xs $i-s $i-4xl 0;
        }

        @include tablet {
            display: none;
        }
    }

    &__top-divider {
        height: 1px;
        background-color: rgba($color-nightly-aurora, 0.2);
        margin-bottom: $i-s;
    }

    &__link {
        @extend .p2;

        display: flex;
        align-items: center;
        width: 260px;
        color: $color-white;
        text-decoration: none;
        padding: $i-s;

        @include item-vertical-gap($i-m);
        @include transition(color, border-color);

        @include hover {
            color: var(--text-color-hover);
            border-color: $color-nightly-aurora;
        }

        @include laptop {
            width: 220px;

            @include item-vertical-gap($i-s);
        }

        &--disabled {
            filter: opacity(0.3);
            pointer-events: none;
            user-select: none;
        }

        &:active, &--active {
            color: $color-nightly-aurora !important;
            border-color: $color-nightly-aurora !important;
        }

        &--bordered {
            border: 1px solid rgba($color-nightly-aurora, 0.2);
            border-radius: 8px;
        }

        &:not(&--bordered) {
            --text-color-hover: #{$color-nightly-aurora};
        }
    }

    &__icon {
        width: 20px;
        height: 20px;
        fill: $color-nightly-aurora;
        margin-right: $i-s;
    }

    &__lang {
        display: none;
        margin-top: 16px;

        @include tablet {
            display: block;
        }
    }
}
